import React, { useState, useEffect } from 'react';
import './SampleCustomization.css'; // Create a corresponding CSS file for styling
import { getDocuments, deleteDocuments } from './DataAccess';
import LoadingScreen from './LoadingScreen'
import Popup from './Popup';


const DocumentCusotmization = ({ list, setList, onClose }) => {
    const [selectedSamples, setSelectedSamples] = useState(list);
    const [isAddingNewSample, setIsAddingNewSample] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [okState, setOkState] = useState('delete')

    const [popupMessage, setPopupMessage] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [isAddDisabled, setIsAddDisabled] = useState(true);
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [dbData, setdbData] = useState(true);

    var user = {
        isPremium: JSON.parse(localStorage.getItem("userStatus")).isPremium,
        email: JSON.parse(localStorage.getItem("userStatus")).email
    }
    document.body.style.overflowY = "hidden"
    useEffect(() => {
        if (list != null && list.length > 0) {
            setIsAddDisabled(false);
            if (selectedSamples.length > 0){
                setIsDeleteDisabled(false)
            }
            else{
                setIsDeleteDisabled(true);
            }

        }
        else {
            setIsAddDisabled(true)
            setSelectedSamples([])
        }

        const fetchData = async () => {
            setLoading(true)
            const remoteData = await getDocuments(user.email, ["question", "paper", "summary"]);
            if (remoteData.success) {
                let data = remoteData.data;
                let documents = []
                if (data != false && data != null) {
                    data.forEach((element, index) => {
                        var formattedDate = "";
                        if (element.value.timestamp != null) {
                            let timestampSeconds = element.value.timestamp._seconds;
                            const date = new Date(timestampSeconds * 1000); // Convert seconds to milliseconds
                            formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
                            documents.push({ id: index, name: element.name, date: formattedDate });
                        }
                    })                // setIsDeleteDisabled(true);

                }
                setDocuments(documents);
                // if (list != null) {
                //     setIsAddDisabled(true)
                // }
            }
            else if (!remoteData.success) {
                if (remoteData.userNotFound) {
                    setLoading(false);
                    alert("Not signed in")
                }
                else if (remoteData.documentsNotFound) {
                    console.log("Documents not found")
                }
            }
            else {
                console.error('Error fetching data');
                alert("Error loading data")
            }
            setLoading(false);
        };

        fetchData();
    }, [dbData]);

    const handleSampleClick = (sample) => {
        const isSampleSelected = selectedSamples.some((s) => s === sample);
        if (isSampleSelected) {
            setSelectedSamples(selectedSamples.filter((s) => s !== sample));
            var selectedSample = document.getElementById(`document-${sample}`);
            selectedSample.classList.remove("selected");
            if (selectedSamples.filter((s) => s !== sample).length === 0) {
                setIsAddDisabled(true);
                if (list != null && list != []) {
                    setIsAddDisabled(false);
                }
                setIsDeleteDisabled(true);
            }
        } else {
            setSelectedSamples([...selectedSamples, sample]);
            var selectedSample = document.getElementById(`document-${sample}`);
            selectedSample.classList.add("selected");
            if (list != null && list != []) {
                setIsAddDisabled(false);
            }
            else{
                setIsAddDisabled(true);
            }
            setIsDeleteDisabled(false);
        }
        
    };


    const handleAddNewSample = () => {
        setIsAddingNewSample(!isAddingNewSample); // Toggle the state
    };

    const handleCancel = async () => {
        console.log("Nothing")
    }
    const handleOK = async () => {
        if (okState == 'delete') {
            await handleDelete(true);
        }
    }

    const handleDelete = async (isOverride) => {
        if (isOverride == true) {
            setLoading(true);
            await deleteDocuments(user.email, selectedSamples);
            if (setList != null){
                setList(documents.filter((s) => !selectedSamples.includes(s.name)))
            }
            setSelectedSamples([])
            setLoading(false);
            setdbData(!dbData);
            onClose();
        }
        else {
            setOkState('delete');
            setPopupMessage("You are about to delete the selected documents, would you like to continue?")
            setPopupVisible(true)
        }
    };

    return (
        <div className={`sample-customization-overlay ${isAddingNewSample ? 'active' : ''}`}>
            <div className={`sample-customization ${isAddingNewSample ? 'active' : ''}`}>
                <LoadingScreen loading={loading} />
                <Popup message={popupMessage} isVisible={popupVisible} onOK={handleOK} onCancel={handleCancel} setPopupVisible={setPopupVisible} />

                <span className="close" onClick={onClose}>&times;</span>
                <h2>Documents Customization</h2>

                <div id="add-to-context" className="button-container">
                    <button id="remove-sample" className="action-button" onClick={handleDelete} disabled={isDeleteDisabled}>
                        <span role="img" aria-label="Trash Can">
                            🗑️
                        </span>
                    </button>

                </div>

                <div className="sample-list">
                    <h3>Your documents</h3>
                    {documents.map((document) => (
                        <div key={document.name}>
                            <button
                                id={`document-${document.name}`}
                                className={`sample-item ${(selectedSamples ?? []).some(entry => entry === document.name) ? 'selected' : ''}`}
                                onClick={(e) => handleSampleClick(document.name)}
                            >
                                <div className='document-info'>
                                    <h3>{document.name}</h3>
                                    <p>Date: {document.date}</p>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DocumentCusotmization;
