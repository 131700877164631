import React from 'react';
import './Popup.css';

const Popup = ({ message, isVisible, onOK, onCancel, setPopupVisible }) => {
  const handleOK = async () => {
    setPopupVisible(false);
    if (onOK) {
      await onOK();
    }
  };

  const handleCancel = async () => {
    setPopupVisible(false);
    if (onCancel) {
      await onCancel();
    }
  };

  return (
    <div id="popup" className={`popup ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="popup-content">
        <p>{message}</p>
        <div className="button-container">
          <button onClick={handleOK}>OK</button>
          <button onClick={handleCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
