import React from 'react';
import './QuestionBoxes.css';

const TrueFalseBox = ({ question, solution, isSolution,  id, updateSelectedQuestions }) => {

  const isTrueHighlighted = isSolution && solution.toLowerCase() === 'true';
  const isFalseHighlighted = isSolution && solution.toLowerCase() === 'false';

  return (
    <div key={String(id)} id={String(id)} className={`question-box true-false ${isTrueHighlighted ? 'true-highlighted' : ''} ${isFalseHighlighted ? 'false-highlighted' : ''}`} onClick={() => updateSelectedQuestions(id)}>
      <div className="question">{question}</div>
      <div className="options">
        <div className={`option ${isTrueHighlighted ? 'highlighted' : ''}`}>True</div>
        <div className={`option ${isFalseHighlighted ? 'highlighted' : ''}`}>False</div>
      </div>
    </div>
  );
};

export default TrueFalseBox;
