import React, { useState, useEffect } from "react";
import './ToolTip.css';

const ToolTip = ({toolTipText}) => {
  const [isHovered, setHovered] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    setOpen(true);

    // Automatically close the tooltip after 5 seconds
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  // Use useEffect to reset isOpen state when isHovered changes
  useEffect(() => {
    if (!isHovered) {
      setOpen(false);
    }
  }, [isHovered]);

  return (
    <div className="info-icon-container">
        <link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css" />

      <div
        id='info-tooltip'
        className='info-tooltip'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <i className="ionicons ion-ios-information-outline"></i>
      </div>
      <div id='tooltip' className={`tooltip ${isHovered || isOpen ? 'tooltip-visible' : ''}`}>
        {toolTipText}
      </div>
    </div>
  );
}

export default ToolTip;
