import React, { useState, useEffect } from 'react';
import './SampleCustomization.css'; // Create a corresponding CSS file for styling
import { saveSample, getSamples, deleteSamples } from './DataAccess';
import LoadingScreen from './LoadingScreen'
import Popup from './Popup';


const SampleCustomization = ({ list, setList, onClose }) => {
    const [selectedSamples, setSelectedSamples] = useState(list);
    const [isAddingNewSample, setIsAddingNewSample] = useState(false);
    const [samples, setSamples] = useState([]);

    const [sampleName, setSampleName] = useState("");
    const [sampleContent, setSampleContent] = useState("");
    const [okState, setOkState] = useState('save')

    const [popupMessage, setPopupMessage] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [isAddDisabled, setIsAddDisabled] = useState(true);
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [dbData, setdbData] = useState(true);

    var user = {
        isPremium: JSON.parse(localStorage.getItem("userStatus")).isPremium,
        email: JSON.parse(localStorage.getItem("userStatus")).email
    }
    document.body.style.overflowY = "hidden"
    useEffect(() => {
        if (list != null && list.length > 0) {
            setIsAddDisabled(false);
            if (selectedSamples.length > 0){
                setIsDeleteDisabled(false)
            }
            else{
                setIsDeleteDisabled(true);
            }

            // setSelectedSamples(list.map(entry => entry?.name).filter(name => name !== undefined && name !== null))
            // console.log(selectedSamples)
            // console.log((selectedSamples ?? []).some(entry => entry === "Test") ? 'selected' : '')
        }
        else {
            setIsAddDisabled(true)
            setSelectedSamples([])
        }

        const fetchData = async () => {
            setLoading(true)
            const remoteData = await getSamples(user.email);
            if (remoteData.success) {
                let data = remoteData.data;
                let samples = []
                if (data != false && data != null) {
                    data.forEach((element, index) => {
                        var formattedDate = "";
                        if (element.value.timestamp != null) {
                            let timestampSeconds = element.value.timestamp._seconds;
                            const date = new Date(timestampSeconds * 1000); // Convert seconds to milliseconds
                            formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
                            samples.push({ id: index, name: element.name, description: element.value.contents.sampleContent.slice(0, 9) + "...", date: formattedDate });
                        }
                    })                // setIsDeleteDisabled(true);

                }
                setSamples(samples);
                // if (list != null) {
                //     setIsAddDisabled(true)
                // }
            }
            else if (!remoteData.success) {
                if (remoteData.userNotFound) {
                    setLoading(false);
                    alert("Not signed in")
                }
                else if (remoteData.documentsNotFound) {
                    console.log("Documents not found")
                }
            }
            else {
                console.error('Error fetching data');
                alert("Error loading data")
            }
            setLoading(false);
        };

        fetchData();
    }, [dbData]);

    const handleSampleClick = (sample) => {
        const isSampleSelected = selectedSamples.some((s) => s === sample);
        if (isSampleSelected) {
            setSelectedSamples(selectedSamples.filter((s) => s !== sample));
            var selectedSample = document.getElementById(`sample-${sample}`);
            selectedSample.classList.remove("selected");
            if (selectedSamples.filter((s) => s !== sample).length === 0) {
                setIsAddDisabled(true);
                if (list != null && list != []) {
                    setIsAddDisabled(false);
                }
                setIsDeleteDisabled(true);
            }
        } else {
            setSelectedSamples([...selectedSamples, sample]);
            var selectedSample = document.getElementById(`sample-${sample}`);
            selectedSample.classList.add("selected");
            if (list != null && list != []) {
                setIsAddDisabled(false);
            }
            else{
                setIsAddDisabled(true);
            }
            setIsDeleteDisabled(false);
        }
        
    };


    const handleAddNewSample = () => {
        setIsAddingNewSample(!isAddingNewSample); // Toggle the state
    };

    const handleCancel = async () => {
        console.log("Nothing")
    }
    const handleOK = async () => {
        if (okState == 'save') {
            await handleSave(true);
        }
        else if (okState == 'delete') {
            await handleDelete(true);
        }
    }

    const handleAddToContext = () => {
        if (list != null) {
            setList(samples.filter((s) => selectedSamples.includes(s.name)))
        }
        onClose();
    };

    const handleDelete = async (isOverride) => {
        if (isOverride == true) {
            setLoading(true);
            await deleteSamples(user.email, selectedSamples);
            if (setList != null){
                setList(samples.filter((s) => !selectedSamples.includes(s.name)))
            }
            setSelectedSamples([])
            setLoading(false);
            setdbData(!dbData);
            onClose();
        }
        else {
            setOkState('delete');
            setPopupMessage("You are about to delete the selected documents, would you like to continue?")
            setPopupVisible(true)
        }
    };

    const handleSave = async (isOverride) => {
        // Handle saving the new sample data
        if (sampleName != "" && sampleContent != "") {
            setOkState("save");
            setIsAddingNewSample(false);
            var saveButton = document.getElementById("saveButton");
            var content = { sampleContent: sampleContent }
            var context = { userEmail: user.email, name: sampleName, name: sampleName, content: content }
            setLoading(true);

            await saveSample(isOverride, sampleName, context, setPopupMessage, setPopupVisible)
                .then((result) => {
                    if (result.notPremium) {
                        alert("You do not have a premium account and cannot use this feature");
                    }
                    else if (result === true) {
                        setSampleContent("");
                        setSampleName("");
                        setdbData(!dbData);
                    }
                })
            setLoading(false);
            setdbData(!dbData);
        }
        else {
            alert("Create a sample first!")
        }
        document.body.style.overflowY = "auto"
    };

    return (
        <div className={`sample-customization-overlay ${isAddingNewSample ? 'active' : ''}`}>
            <div className={`sample-customization ${isAddingNewSample ? 'active' : ''}`}>
                <LoadingScreen loading={loading} />
                <Popup message={popupMessage} isVisible={popupVisible} onOK={handleOK} onCancel={handleCancel} setPopupVisible={setPopupVisible} />

                <span className="close" onClick={onClose}>&times;</span>
                <h2>Sample Customization</h2>

                <div id="add-to-context" className="button-container">
                    <button className="action-button" onClick={handleAddToContext} disabled={isAddDisabled}>
                        {list != null && list.length > 0 ? 'Update Context' : 'Add to Context'}
                    </button>
                    <button id="remove-sample" className="action-button" onClick={handleDelete} disabled={isDeleteDisabled}>
                        <span role="img" aria-label="Trash Can">
                            🗑️
                        </span>
                    </button>
                    <button className="action-button" onClick={handleAddNewSample}>
                        <i className={`fa fa-${isAddingNewSample ? 'minus' : 'plus'}`}></i>

                    </button>
                </div>

                {isAddingNewSample && (
                    <div className="new-sample-section">
                        <h3>Add New Sample</h3>
                        <div>
                            <label>Sample Name:</label>
                            <input type="text" value={sampleName} onChange={(e) => setSampleName(e.target.value)} />
                        </div>
                        <div>
                            <label>Sample Content:</label>
                            <textarea value={sampleContent} onChange={(e) => setSampleContent(e.target.value)}></textarea>
                        </div>
                        <div className='button-container'>
                            <button id="saveButton" onClick={handleSave}>Save</button>

                        </div>
                    </div>
                )}

                <div className="sample-list">
                    <h3>Your Samples</h3>
                    {samples.map((sample) => (
                        <div key={sample.name}>
                            <button
                                id={`sample-${sample.name}`}
                                className={`sample-item ${(selectedSamples ?? []).some(entry => entry === sample.name) ? 'selected' : ''}`}
                                onClick={(e) => handleSampleClick(sample.name)}
                            >
                                <div className='document-info'>
                                    <h3>{sample.name}</h3>
                                    <p>Date: {sample.date}</p>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SampleCustomization;
