import React from "react";
import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer-section">
            <hr></hr>
            <div className="footer-content">
                <div className="footer-legal">
                    <h2>About</h2>
                    <div className="footer-links">
                        <strong><a href="/copyright">Copyright Notice</a></strong>
                        <strong><a href="/privacy">Privacy Policy</a></strong>
                        <strong><a href="/">Sitemap</a></strong>
                    </div>
                </div>

                {/* <div className="footer-logo">
            <img src={process.env.PUBLIC_URL + "/images/your-logo.png"} alt="Your Logo" />
          </div> */}
                <div className="footer-contact">
                    <h2>Contact</h2>
                    <div className="footer-contact">
                        <strong><a href="mailto:your@email.com">csupport@paschidev.com</a></strong>
                        <strong><a href="https://forms.gle/cs26MjzB3u3A73h48">Feedback Form</a></strong>
                    </div>
                </div>

                <div className="footer-social-media">
                    <div className="footer-media">
                    <h2>Social media</h2>
                    {/* Add your social media icons and hyperlinks */}
                    <a href="https://tiktok.com">
                        <img src={process.env.PUBLIC_URL + "/images/brands/tiktok.svg"} alt="tiktok" />
                    </a>
                    <a href="https://instagram.com">
                        <img src={process.env.PUBLIC_URL + "/images/brands/instagram.svg"} alt="tiktok" />
                    </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;