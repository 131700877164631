// UserInfoModal.js
import React from 'react';
import './UserInfoModal.css'; // Create a corresponding CSS file for styling


const UserInfoModal = ({ email, isPremium, onPremiumClick, onSignOutClick, onClose }) => {
    return (
      <div className={`user-info-modal-overlay ${isPremium ? 'premium' : 'not-premium'}`}>
        <div className="user-info-modal">
          <span className="close" onClick={onClose}>&times;</span>
          <div className="modal-content">
            <h2> {email}</h2>
            <h2 className={isPremium ? 'premium' : 'not-premium'}>
              {isPremium ? 'Premium User' : 'Free User'}
            </h2>
            <p>Your account is {isPremium ? 'premium.' : 'not currently premium.'}</p>
            {!isPremium && (
              <button className="premium-button" onClick={onPremiumClick}>Get Premium</button>
            )}
            <button onClick={onSignOutClick}>Sign Out</button>
          </div>
        </div>
      </div>
    );
  };
  
  export default UserInfoModal;
  
