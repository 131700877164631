// QuestionGenerator.js
import { React, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import html2pdf from 'html2pdf.js'
import { v4 as uuidv4 } from 'uuid';

import './QuestionGenerator.css'; // Create a CSS file for styling
import './common/ServicesStyles.css';

import Header from './common/Header';
import InputUploadSection from './common/InputUploadSection';
import LoadingScreen from './common/LoadingScreen';
import Popup from './common/Popup';
import { getDocumentData, saveDocument, firebaseConfig } from './common/DataAccess';
import { DownloadButton, BackButton } from './common/Buttons'


import MultipleChoiceBox from './questions/MultipleChoiceBox';
import TrueFalseBox from './questions/TrueFalseBox';
import ShortAnswerBox from './questions/ShortAnswerBox';

import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import Footer from './common/Footer';


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "127.0.0.1", 5001); //DEV


const QuestionGenerator = () => {
    const location = useLocation();
    const { docName, importId } = location.state || {};
    const user = {
        isPremium: JSON.parse(localStorage.getItem("userStatus")).isPremium,
        email: JSON.parse(localStorage.getItem("userStatus")).email,
    }
    const documentType = "question"
    // const waveBackgroundImageUrl = process.env.PUBLIC_URL + "/images/stacked-waves-haikei-blue-grey.svg"


    const [format, setFormat] = useState('Multiple Choice');
    const [activeTab, setActiveTab] = useState('questions'); // 'questions' or 'solutions'
    const [numQuestions, setNumQuestions] = useState(1);
    const [numberOfOptions, setNumberOfOptions] = useState(4); // Initial value, adjust as needed
    const [difficulty, setDifficulty] = useState("Easy");
    const [questions, setQuestions] = useState([]);
    const [solutions, setSolutions] = useState([]);
    const [isClearDisabled, setIsClearDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [questionData, setQuestionData] = useState([]);
    const [dbData, setdbData] = useState(null);
    const [id, setId] = useState(importId);
    const [selectedItems, setSelectedItems] = useState([]);


    window.onbeforeunload = function () {
        return "Data will be lost if you leave the page, make sure you save everything you need to save?";
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const remoteData = await getDocumentData(user.email, docName, "question");
                setLoading(false);
                setdbData(remoteData);

            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);

            }
        };
        fetchData();
        setLoading(false);

    }, []);

    const handleFormatChange = (value) => {
        setFormat(value);
    };

    const handleNumQuestionsChange = (value) => {
        setNumQuestions(value);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleDifficultyChange = (value) => {
        setDifficulty(value);
    }

    const handleOK = async () => {
        await handleSave(true);
    }
    const handleCancel = async () => {
        console.log("Nothing")
    }

    const updateDOMSelection = (items) => {
        setIsClearDisabled(items.length === 0);

        // Select newly added items
        items.forEach((item) => {
            const selectedItem = document.getElementById(`${item}`);
            if (selectedItem) {
                selectedItem.classList.add("selected");
            }
        });

        // Unselect items that are not in the new selection
        questionData.filter((obj) => !items.includes(obj.uuid)).forEach((item) => {
            const unselectedItem = document.getElementById(`${item.uuid}`);
            if (unselectedItem && !items.includes(item)) {
                unselectedItem.classList.remove("selected");
            }
        });
    };

    const handleSelectedQuestionsChange = (target) => {
        setSelectedItems((prevSelectedItems) => {
            const isSampleSelected = prevSelectedItems.includes(target);
            const newSelectedItems = isSampleSelected
                ? prevSelectedItems.filter((s) => s !== target)
                : [...prevSelectedItems, target];

            // Call updateDOMSelection within handleSelectedQuestionsChange
            updateDOMSelection(newSelectedItems);

            return newSelectedItems;
        });
    };

    useEffect(() => {
        // Use updateDOMSelection as part of the effect
        updateDOMSelection(selectedItems);
    }, [selectedItems, questionData]);


    const handleClearClicked = async () => {
        if (selectedItems != null) {
            const filteredArray = questionData.filter((obj) => !selectedItems.includes(obj.uuid));
            setQuestionData([]);
            setQuestions([]);
            setSolutions([]);
            filteredArray.forEach((element, index) => {
                createQuestionBoxes(element)
            })
            setSelectedItems([]);
        }
    }

    const handleSave = async (isOverride) => {
        var saveButton = document.getElementById("saveButton");
        var questionSource = document.getElementById("inputTextArea");
        var documentName = document.getElementById("documentNameInput");
        var content = { format: format, numQuestions: numQuestions, numberOfOptions: numberOfOptions, difficulty: difficulty, questionData: questionData, questionSource: questionSource.value }
        var context = { id: id, userEmail: user.email, name: documentName.value, documentType: documentType, content: content }
        setLoading(true);
        saveButton.disabled = true;


        await saveDocument(isOverride, documentName, context, setPopupMessage, setPopupVisible)
            .then((result) => {
                if (result.notPremium) {
                    alert("You do not have a premium account and cannot use this feature");
                }
                else if (result.success) {
                    setId(result.id);
                }
            })

        setLoading(false);
        saveButton.disabled = false;
    };


    function createQuestionBoxes(element) {
        var q;
        var s;
        var uuid;
        var type;
        if (element.uuid == null) {
            const generateUUID = () => {
                let newUuid = uuidv4();
                if (questions.map((q) => q.key).includes(uuid)) {
                    newUuid = generateUUID();
                }
                return newUuid;
            };
            uuid = generateUUID();
            element["uuid"] = uuid;
        }
        else {
            uuid = element.uuid
        }
        if (element.type == null){
            element["type"] = format;
            type = format;
        }
        else{
            type = element.type;
        }
        setQuestionData((prevQuestions) => [...prevQuestions, element]);
        if (type.toLowerCase() === 'multiple choice') {
            q = <MultipleChoiceBox {...element} key={uuid} id={uuid} updateSelectedQuestions={handleSelectedQuestionsChange} />;
            s = <MultipleChoiceBox {...element} key={uuid} id={uuid} updateSelectedQuestions={handleSelectedQuestionsChange} isSolution={true} />;
        } else if (type.toLowerCase() === 'true false') {
            q = <TrueFalseBox {...element} key={uuid} id={uuid} updateSelectedQuestions={handleSelectedQuestionsChange} />;
            s = <TrueFalseBox {...element} key={uuid} id={uuid} updateSelectedQuestions={handleSelectedQuestionsChange} isSolution={true} />;
        }
        else if (type.toLocaleLowerCase() === 'short answer') {
            q = <ShortAnswerBox {...element} key={uuid} id={uuid} updateSelectedQuestions={handleSelectedQuestionsChange} />;
            s = <ShortAnswerBox {...element} key={uuid} id={uuid} updateSelectedQuestions={handleSelectedQuestionsChange} isSolution={true} />;
        }
        // Update the state to include the new elements
        if (!questions.includes(q)) {
            setQuestions((prevQuestions) => [...prevQuestions, q]);
        }
        if (!solutions.includes(s)) {
            setSolutions((prevSolutions) => [...prevSolutions, s]);
        }
    }

    const handleGenerate = async () => {
        var questionSource = document.getElementById("inputTextArea");
        if (questionSource.value.trim() == "") {
            alert("Please enter a valid question source")
        }
        else {
            var generateButton = document.getElementById("generateButton");
            var questionObj = { userEmail: user.email, questionSource: questionSource.value, numberQuestions: numQuestions, questionType: format, questionDifficulty: difficulty, questionOptions: numberOfOptions, tokenLimit: 100 }
            const createQuestion = httpsCallable(functions, 'createQuestion');
            setLoading(true);
            generateButton.disabled = true;
            await createQuestion({ questionObj: questionObj })
                .then((result) => {
                    const data = result.data;
                    if (result.data.notPremium) {
                        alert("You do not have a premium account and cannot use this feature");
                    }
                    else if (data.questions != null) {
                        data.questions.forEach((element) => {
                            createQuestionBoxes(element)

                        });
                        setLoading(false);
                        generateButton.disabled = false;
                    }

                })
                .catch((ex) => {
                    console.log(ex)
                    setLoading(false);
                    alert("There was a problem generating your questions.")
                    generateButton.disabled = false;
                })
        }
    }

    const handleDownload = async () => {
        setLoading(true);
        const questionsList = document.getElementById('questionsList');
        const solutionsList = document.getElementById('solutionsList');

        var content;
        var docName;
        var questionSource = document.getElementById("inputTextArea").value;
        if (questionsList != null && questionData != null && questionData.length() > 0) {
            content = questionsList;
            docName = questionSource.slice(0, 21) + "-questions.pdf"
        }
        else if (solutionsList != null && questionData != null && questionData.length() > 0) {
            content = solutionsList;
            docName = questionSource.slice(0, 21) + "-solutions.pdf"
        }
        else {
            alert("Create some questions first!")
        }
        // Configuration for html2pdf
        const pdfOptions = {
            margin: 10,
            filename: docName,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css'] }
        };
        await html2pdf().set(pdfOptions).from(content).save()
            .catch((ex) => {
                console.log(ex)
            });
        setLoading(false);
    }


    const loadFromStorage = async () => {
        var documentData = dbData.data.contents;
        if (dbData.success) {
            setdbData(null);
            setFormat(documentData.format);
            setDifficulty(documentData.difficulty);
            setNumQuestions(documentData.numQuestions);
            setNumberOfOptions(documentData.numberOfOptions);
            setQuestionData(documentData.questionData);
            var questionSource = document.getElementById("inputTextArea");
            questionSource.value = documentData.questionSource;
            if (documentData.questionData.length > 0) {
                setQuestionData([]);
                setQuestions([]);
                setSolutions([]);
                documentData.questionData.forEach((element, index) => {
                    createQuestionBoxes(element)
                })
            }
        }
        else {
            console.log("Unable to get document data")
        }
    }

    if (user != null && docName != null && dbData != null) {
        setLoading(true);
        loadFromStorage()
        setLoading(false);

    }

    return (
        <div className="question-generator">
            <BackButton />
            <Popup message={popupMessage} isVisible={popupVisible} onOK={handleOK} onCancel={handleCancel} setPopupVisible={setPopupVisible} />
            <LoadingScreen loading={loading} />
            <Header handleSave={handleSave} headerTitle={"Question Generator"} docName={docName} />
            {/* <WaveBackground backgroundUrl={waveBackgroundImageUrl} /> */}
            <div className='vertical-layout fly-in'>
                <InputUploadSection inputType={"Questions"} handleGenerate={handleGenerate} />
                <div className="output-container">
                    <div className='title'>
                        <h3>Generated Questions</h3>
                    </div>
                    <div className="generation-customization">
                        {/* Format Dropdown */}
                        <div className="options-dropdown">
                            <label className='customization-label'>Format:</label>
                            <select id="format" value={format} onChange={(e) => handleFormatChange(e.target.value)}>
                                <option value="Multiple Choice">Multiple Choice</option>
                                <option value="True False">True False</option>
                                <option value="Short Answer">Short Answer</option>
                            </select>
                        </div>

                        {/* Difficulty Dropdown */}
                        <div className="options-dropdown">
                            <label className='customization-label'>Difficulty:</label>
                            <select id="difficulty" value={difficulty} onChange={(e) => handleDifficultyChange(e.target.value)}>
                                <option value="Easy">Easy</option>
                                <option value="Medium">Medium</option>
                                <option value="Hard">Hard</option>
                                <option value="Expert">Expert</option>
                            </select>
                        </div>
                    </div>


                    <div className='generation-customization'>

                        {/* Number of Questions */}
                        <div className="spinner-1">
                            <label className='customization-label'>Number of Questions:</label>
                            <input id="numberQuestions" className='generation-input'
                                type="number"
                                value={numQuestions}
                                onChange={(e) => handleNumQuestionsChange(e.target.value)}
                            />
                        </div>

                        <div className="options-slider" >
                            <label className='customization-label'>Number of Options:</label>
                            <input id="numberOptions" className='generation-input'
                                type="range"
                                min="2"
                                max="6"
                                value={numberOfOptions}
                                onChange={(e) => setNumberOfOptions(Number(e.target.value))}
                                disabled={format != 'Multiple Choice'}
                            />
                            <span>{numberOfOptions}</span>
                        </div>
                    </div>

                    {/* Tabs */}
                    <div className="tabs">
                        <button
                            className={activeTab === 'questions' ? 'active' : ''}
                            onClick={() => handleTabChange('questions')}
                        >
                            Questions
                        </button>
                        <button
                            className={activeTab === 'solutions' ? 'active' : ''}
                            onClick={() => handleTabChange('solutions')}
                        >
                            Solutions
                        </button>

                        <button disabled={isClearDisabled} onClick={handleClearClicked}>
                            Clear
                        </button>
                        <DownloadButton generateFile={handleDownload} />
                    </div>

                    <hr className='tab-hr'></hr>
                    <div>
                        {/* Content based on the active tab (questions or solutions) */}
                        {activeTab === 'questions' && (
                            <div id="questionsList" className="questions-tab">
                                {questions}
                            </div>
                        )}
                        {activeTab === 'solutions' && (
                            <div id="solutionsList" className="solutions-tab">
                                {solutions}
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <Footer />
        </div>

    );
};

export default QuestionGenerator;


