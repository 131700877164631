import React from 'react';
import './QuestionBoxes.css';

const MultipleChoiceBox = ({ question, options, solution, isSolution, id, updateSelectedQuestions }) => {
  return (
    <div key={String(id)} id={String(id)} className="question-box multiple-choice" onClick={() => updateSelectedQuestions(id)}>
      <div className="question">{question}</div>
      <div className="options">
        {options.map((option, index) => (
          <div key={index} className={`option ${isSolution && solution.toLowerCase() === option.toLowerCase() ? 'highlighted' : ''}`}>
            {String.fromCharCode(65 + index)}: {option}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleChoiceBox;

