import React from 'react';
import './QuestionBoxes.css';

const ShortAnswerBox = ({ question, solution, isSolution, id, updateSelectedQuestions }) => {
  return (
    <div key={id} id={String(id)} className="question-box short-answer" onClick={() => updateSelectedQuestions(id)}>
      {!isSolution &&<div className="question">{question}</div>}
      {isSolution && <div className="answer">{solution}</div>}
    </div>
  );
};

export default ShortAnswerBox;

