import React from "react";
import ReactQuill from "react-quill";
import katex from "katex";
import "katex/dist/katex.min.css";
window.katex = katex;

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorHtml: "",
      theme: "snow",
      placeholder: "Generated text will show up here, feel free to edit it!",
    };
    this.handleChange = this.handleChange.bind(this);
  }


  updateProps(html) {
    if (this.props.onEditorChange) {
      this.props.onEditorChange(html); // Notify the parent component of the change
    }
  }

  setEditorHtml(html) {
    this.setState({ editorHtml: html })
  }

  handleChange(html) {
    this.setState({ editorHtml: html }, function () {
      this.updateProps(html);
    });
  }

  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme });
  }

  // Method to add content based on the provided object
  addContentFromObject(contentObject) {
    const formattedContent = this.formatContent(contentObject);
    this.setState((prevState) => ({
      editorHtml: prevState.editorHtml + formattedContent
    }), function() {
      this.updateProps(this.state.editorHtml);
    });
    // this.setEditorHtml();
    // this.updateProps(this.editorHtml);
    // this.setState((prevState) => ({
    //   editorHtml: prevState.editorHtml + formattedContent,
    // }));
  }

  // Method to format content based on the provided object
  formatContent(contentObject) {
    let formattedContent = "";

    if (contentObject.bps) {
      let innerList = ''
      contentObject.bps.forEach((section) => {
        innerList += `<li>${section.text}</li>`
      })
      formattedContent += `<ul>${innerList}</ul>`
    }
    if (contentObject.bt) {
      formattedContent += `<p>${contentObject.bt}</p>`
    }

    if (contentObject.title) {
      formattedContent += `<h1>${contentObject.title}</h1>`;
    }

    if (contentObject.intro) {
      formattedContent += `<p>${contentObject.intro}</p>`;
      formattedContent += '<br><br/>'
    }
    if (contentObject.body && contentObject.body.length >= 1) {
      contentObject.body.forEach((section) => {
        formattedContent += `<p>${section.text}</p>`;
      });
    }
    if (contentObject.conclusion) {
      formattedContent += '<br><br/>'
      formattedContent += `<p>${contentObject.conclusion}</p>`;
    }
    if (contentObject.citations && contentObject.citations.length >= 1) {
      formattedContent += "<br><br/>"
      contentObject.citations.forEach((section) => {
        formattedContent += `<p>${section.text}</p>`;
      });
    }
    return formattedContent;
  }

  render() {
    return (
      <div>
        <ReactQuill
          style={{ minHeight: '200px' }}
          theme={this.state.theme}
          onChange={this.handleChange}
          value={this.state.editorHtml}
          modules={Editor.modules}
          formats={Editor.formats}
          bounds={".app"}
          placeholder={this.state.placeholder}
        />
      </div>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ header: [1, 2, 3, false] }],

    ["link", "image", "formula"],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ["clean"] // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

export default Editor;