import React, { useState } from 'react';
import { AddSampleButton } from './Buttons'
import SampleCustomization from './SampleCustomization'; // Import your SampleCustomization component

import './InputUploadSection.css'; // Create a corresponding CSS file for styling
import ToolTip from './ToolTip';

const InputUploadSection = ({ inputType, handleGenerate, samples, setSamples }) => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    var toolTipText;

    if (inputType.toLowerCase() === "questions") {
        toolTipText = "Enter a topic or a chunk of text you wish to create questions for.";
    } else if (inputType.toLowerCase() === "summary") {
        toolTipText = "Enter a chunk of text or URL of a page you wish to create a summary for.";
    } else if (inputType.toLowerCase() === "paper") {
        toolTipText = "Enter a topic you wish to create a paper about.";
    }
    function autoExpand() {
        const textarea = document.getElementById('inputTextArea');
        const ius = document.getElementById('inputUploadSection');
        if (textarea != null) {
            textarea.style.height = 'auto';
            textarea.style.height = (textarea.scrollHeight) + 'px';
        }
    }

    const openPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
        document.body.style.overflowY = "auto";
    };

    return (
        <div id="inputUploadSection" className="input-upload-section">
            <div className={`input-sample-split ${samples != null ? "samples" : ""}`}>
                <div className="input-source">
                    <div className='title'>
                        <h3>{inputType} Source</h3>
                        <ToolTip toolTipText={toolTipText} />
                    </div>
                    <textarea id="inputTextArea" rows="1" className='text-area' placeholder={`Enter your ${inputType} source here...`} onChange={autoExpand()} />
                </div>
                {samples && <div className="writing-samples">

                    <div className='title-button-pair'>
                        <div className="title">
                            <h3>Samples</h3>
                        </div>
                        <AddSampleButton setSamplePopUpVisible={openPopup} />
                    </div>

                    {/* List of writing samples */}
                    <ul>
                        {samples.map((sample) => (
                            <li key={sample.id}>
                                <strong>{sample.name}</strong> - {sample.description}
                            </li>
                        ))}
                    </ul>
                </div>
                }
            </div>
            <div className='generate'>
                <button id="generateButton" className='generate-button' onClick={handleGenerate}>
                    Generate
                </button>
            </div>

            {/* Render the popup component conditionally */}
            {isPopupVisible && <SampleCustomization list={samples.map(s => s.name)} setList={setSamples} onClose={closePopup} />}
        </div>
    );
};

export default InputUploadSection;
