import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

export const firebaseConfig = {
    apiKey: "AIzaSyAUZgh8VMw39k7QHPiwgc9Xbc5ZmgbqjFg",
    authDomain: "onestop.qtassistant.ai",
    projectId: "onestop-2541c",
    storageBucket: "onestop-2541c.appspot.com",
    messagingSenderId: "513906045669",
    appId: "1:513906045669:web:302d486918cacec7715a0c",
    measurementId: "G-6Y49QHYZTN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // Initialize Firebase Authentication
const functions = getFunctions(app);

// connectFunctionsEmulator(functions, "127.0.0.1", 5001); //DEV
// connectAuthEmulator(auth, "http://127.0.0.1:9099"); //DEV

export async function signInWithProvider(provider) {
    if (provider === "Google") {
        const authProvider = new GoogleAuthProvider();
        return await signInWithPopup(auth, authProvider)
            .then(async (result) => {
                if (result.user.emailVerified === true) {
                    const setOrUpdateUser = httpsCallable(functions, 'setOrUpdateUser')
                    await setOrUpdateUser(auth.currentUser.email)
                        .then((result) => {
                        })
                }
                return result;
            })
            .catch((ex) => {
                return ex;
            })
    }
}

export async function getPremiumStatus(email) {
    const getPremiumStatus = httpsCallable(functions, 'getPremiumStatus');
    var response = await getPremiumStatus({ userEmail: email })
        .then((result) => {
            if (result.data === true) {
                return true;
            }
            else {
                return false;
            }
        })
        .catch((ex) => {
            console.log(ex);
            return false
        })
    return response;

}

export async function saveDocument(isOverride, documentName, context, setPopupMessage, setPopupVisible) {
    if (isOverride === true) {
        context["override"] = true;
    }
    else {
        context["override"] = false;
    }
    const saveDocument = httpsCallable(functions, 'saveDocument');
    var response = await saveDocument({ context: context })
        .then((result) => {
            const returnData = result.data;
            if (returnData && returnData.success) {
                return returnData;
            }
            else if (!returnData.success && returnData.exists) {
                setPopupMessage(`Document with name '${documentName.value}' already exists, would you like to overwrite it?`);
                setPopupVisible(true);
                return false;
            }
            else if (returnData.notPremium) {
                return { notPremium: true }
            }
            else {
                setPopupMessage(`Document save failed, would you like to try again?`);
                setPopupVisible(true);
                return false;
            }

        })
        .catch((ex) => {
            console.log(ex);
            return false
        })
    return response;
}

export async function saveSample(isOverride, documentName, context, setPopupMessage, setPopupVisible) {
    if (isOverride === true) {
        context["override"] = true;
    }
    else {
        context["override"] = false;
    }
    const saveSample = httpsCallable(functions, 'saveSample');
    var response = await saveSample({ context: context })
        .then((result) => {
            const returnData = result.data;
            if (returnData && returnData.success) {
                return returnData;
            }
            else if (!returnData.success && returnData.exists) {
                if (setPopupMessage != null && setPopupVisible != null) {
                    setPopupMessage(`Sample with name '${documentName}' already exists, would you like to overwrite it?`);
                    setPopupVisible(true);
                }
                return false;
            }
            else if (returnData.notPremium) {
                return { notPremium: true }
            }
            else {
                if (setPopupMessage != null && setPopupVisible != null) {
                    setPopupMessage(`Sample save failed, would you like to try again?`);
                    setPopupVisible(true);
                }
                return false;
            }
        })
        .catch((ex) => {
            console.log(ex);
            return false
        })
    return response;
}

export async function getDocId(userEmail, docName, collection){
    const getId = httpsCallable(functions, 'getId');
    const response = await getId({ userEmail: userEmail, name: docName, collection: collection })
    if (response.data.success){
        return response.data.id
    }
    else{
        return false
    }
}

export async function getDocuments(userEmail, documentType) {
    const getDocuments = httpsCallable(functions, 'getDocuments');
    const response = await getDocuments({ userEmail, documentType })
        .then((result) => {
            return result.data;
        })
        .catch((ex) => {
            console.log(ex);
            return false
        })
    return response;
}

export async function getSamples(userEmail) {
    const getSamples = httpsCallable(functions, 'getSamples');
    const response = await getSamples({ userEmail })
        .then((result) => {
            return result.data;
        })
        .catch((ex) => {
            console.log(ex);
            return false
        })
    return response;
}

export async function getSampleData(userEmail, sampleName) {
    const getSampleData = httpsCallable(functions, 'getSampleData');
    var response = await getSampleData({ userEmail: userEmail, name: sampleName})
        .then((result) => {
            const returnData = result.data;
            return returnData;
        }).catch((ex) => {
            console.log(ex);
            return false
        })
    return response;
}

export async function deleteSamples(email, samples) {
    const deleteSamples = httpsCallable(functions, 'deleteSamples');
    const response = await deleteSamples({ userEmail: email, samples: samples })
        .then((result) => {
            if (result.data.success) {
                return true;
            }
            return false;
        })
        .catch((ex) => {
            console.log(ex);
            return false
        })
    return response;
}

export async function deleteDocuments(email, documents) {
    const deleteDocuments = httpsCallable(functions, 'deleteDocuments');
    const response = await deleteDocuments({ userEmail: email, documents: documents })
        .then((result) => {
            if (result.data.success) {
                return true;
            }
            return false;
        })
        .catch((ex) => {
            console.log(ex);
            return false
        })
    return response;
}


export async function getDocumentData(userEmail, docName, documentType) {
    const getDocumentData = httpsCallable(functions, 'getDocumentData');
    var response = await getDocumentData({ userEmail: userEmail, name: docName, type: documentType })
        .then((result) => {
            const returnData = result.data;
            return returnData;
        }).catch((ex) => {
            console.log(ex);
            return false
        })
    return response;
}