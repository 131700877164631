import { React, useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import html2pdf from 'html2pdf.js'

import './SummaryGenerator.css'; // Create a CSS file for styling
import './common/ServicesStyles.css';

import InputUploadSection from './common/InputUploadSection';
import LoadingScreen from './common/LoadingScreen';
import Header from './common/Header';
import QuillEditor from './common/QuillEditor';
import Popup from './common/Popup';
import { getDocumentData, saveDocument, firebaseConfig } from './common/DataAccess';
import { DownloadButton, BackButton } from './common/Buttons'


import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import Footer from './common/Footer';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "127.0.0.1", 5001); //DEV

const SummaryGenerator = () => {
    const location = useLocation();
    const { docName, importId } = location.state || {};
    const documentType = "summary"

    const user = {
        isPremium: JSON.parse(localStorage.getItem("userStatus")).isPremium,
        email: JSON.parse(localStorage.getItem("userStatus")).email,
    }

    const editorRef = useRef();
    const [wordCount, setWordCount] = useState(100);
    const [summaryData, setSummaryData] = useState("");
    const [format, setFormat] = useState("Point Form")

    const [loading, setLoading] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [dbData, setdbData] = useState(null);
    const [id, setId] = useState(importId);

    window.onbeforeunload = function () {
        return "Data will be lost if you leave the page, make sure you save everything you need to save?";
    };


    useEffect(() => {

        const fetchData = async () => {
            try {
                const remoteData = await getDocumentData(user.email, docName, "summary");
                setdbData(remoteData);
                if (remoteData.success) {
                    editorRef.current.setEditorHtml(remoteData.data.contents.summaryData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleEditorChange = (html) => {
        setSummaryData(html);
    };

    const handleSetWordCount = (count) => {
        if (count < 1) {
            setWordCount(1);
        }
        else if (count > 1000) {
            setWordCount(1000)
        }
        else {
            setWordCount(count)
        }
    }

    const handleSave = async (isOverride) => {

        setSummaryData(editorRef.current.editorHtml)
        var saveButton = document.getElementById("saveButton");
        var summarySource = document.getElementById("inputTextArea");
        var documentName = document.getElementById("documentNameInput");
        var content = { format: format, wordCount: wordCount, summarySource: summarySource.value, summaryData: summaryData }
        var context = { id: id, userEmail: user.email, name: documentName.value, documentType: documentType, content: content }
        setLoading(true);
        saveButton.disabled = true;

        await saveDocument(isOverride, documentName, context, setPopupMessage, setPopupVisible)
            .then((result) => {
                if (result.notPremium) {
                    alert("You do not have a premium account and cannot use this feature");
                }
                else if (result.success) {
                    setId(result.id);
                }
            })
        setLoading(false);
        saveButton.disabled = false;
    };

    const handleOK = async () => {
        await handleSave(true);
    }
    const handleCancel = async () => {
        console.log("Nothing")
    }
    const handleGenerate = async () => {
        var generateButton = document.getElementById("generateButton");
        var summarySource = document.getElementById("inputTextArea");
        console.log("Generate Clicked")
        if (summarySource.value.trim() == "") {
            alert("Please enter a valid summary source")
        }
        else {
            var summaryObj = { userEmail: user.email, summarySource: summarySource.value, wordCount: wordCount, formatting: format }
            const createSummary = httpsCallable(functions, 'createSummary');
            setLoading(true);
            generateButton.disabled = true;
            await createSummary({ summaryObj: summaryObj })
                .then((result) => {
                    generateButton.disabled = false;
                    const data = result.data;
                    if (result.data.notPremium) {
                        alert("You do not have a premium account and cannot use this feature");
                    }
                    else {
                        if (data != null) {
                            editorRef.current.addContentFromObject(data)
                        }
                    }
                })
                .catch((ex) => {
                    console.log(ex);
                    setLoading(false);
                    generateButton.disabled = false;
                    alert("There was a problem generating your questions.")
                })
            setLoading(false);
        }
    }

    const handleDownload = async () => {
        setLoading(true);
        var content;
        var docName;
        var summarySource = document.getElementById("inputTextArea");
        if (summaryData != null && summaryData.trim() != "") {
            content = summaryData;
            docName = summarySource.value.slice(0, 21) + "-summary.pdf"
        }
        else {
            alert("Generate some text first!")
        }
        // Configuration for html2pdf
        const pdfOptions = {
            margin: 10,
            filename: docName,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css'] }
        };
        await html2pdf().set(pdfOptions).from(content).save()
            .catch((ex) => {
                console.log(ex)
            });
        setLoading(false);
    }

    const loadFromStorage = async () => {
        var documentData = dbData.data.contents;
        if (dbData.success) {
            setLoading(true);
            setdbData(null);
            setFormat(documentData.format);
            setWordCount(documentData.wordCount);
            if (documentData.summaryData != null) {
                setSummaryData(documentData.summaryData);
            }
            var summarySource = document.getElementById("inputTextArea");
            summarySource.value = documentData.summarySource;
            setLoading(false);
        }
        else {
            console.log("Unable to get document data")
        }
    }

    if (user != null && docName != null && dbData != null) {
        loadFromStorage()
    }

    return (
        <div className="summary-generator">
            <Popup message={popupMessage} isVisible={popupVisible} onOK={handleOK} onCancel={handleCancel} setPopupVisible={setPopupVisible} />
            <LoadingScreen loading={loading} />
            <BackButton />
            <Header handleSave={handleSave} headerTitle={"Summary Generator"} docName={docName} />
            <div className='vertical-layout fly-in'>
                <InputUploadSection inputType={"Summary"} handleGenerate={handleGenerate} />
                <div className='output-container'>
                    <div className='title'>
                        <h3>Generated Summary</h3>
                    </div>
                    <div className="generation-customization">
                        <div className="options-dropdown">
                            {/* Add combo box for formatting options */}
                            <label className='customization-label'>Format: </label>
                            <select id="format" value={format} onChange={(e) => setFormat(String(e.target.value))}>
                                <option value="Point Form">Point Form</option>
                                <option value="BLock Text">Block Text</option>
                            </select>
                        </div>
                        <div className="spinner-2">
                            <label className='customization-label'>Word Count:</label>
                            <input id="wordCount" className='generation-input'
                                type="number"
                                value={wordCount}
                                onChange={(e) => handleSetWordCount(e.target.value)}
                            />
                        </div>

                    </div>

                    <hr className='tab-hr'></hr>
                    <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet"></link>
                    <QuillEditor ref={editorRef} onEditorChange={handleEditorChange} />
                    <div className='downloadContainer'>
                        <DownloadButton generateFile={handleDownload} />
                    </div>
                </div>
            </div>
            <Footer />

        </div>

    )
}

export default SummaryGenerator;