import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import PremiumLoginBox from './PremiumLoginBox'; // Update the path based on your project structure
import DocumentConfigBox from './DocumentConfigBox';
import './Header.css'; // Create a corresponding CSS file for styling
import WaveBackground from './WaveBackground';


const Header = ({ handleSave, headerTitle, docName}) => {
    const location = useLocation();
    const { user } = location.state || {};
    // const [waveBackgroundImageUrl, setWaveBackgroundImageUrl] = useState(process.env.PUBLIC_URL + "/images/desktop/blob-scene-haikei (1).svg");
    const [waveBackgroundImageUrl, setWaveBackgroundImageUrl] = useState(process.env.PUBLIC_URL + "/images/blob-scene-haikei-1.svg");

    useEffect(() => {
        let mobileDir = "desktop/"
        let numChoices = 11;
        const isMobile = navigator.userAgentData.mobile;
        if (isMobile){
            mobileDir = "mobile/"
            numChoices = 6;
        }
        let randChoice = Math.floor(Math.random() * (numChoices - 1 + 1)) + 1;
        setWaveBackgroundImageUrl(process.env.PUBLIC_URL + `/images/${mobileDir}blob-scene-haikei-${randChoice}.svg`)
      }, []);

    //   console.log(waveBackgroundImageUrl)

    return (
        <header className="header">
            <WaveBackground backgroundUrl={waveBackgroundImageUrl}/>
            <div className="header-container">
            <div className="title slide-up">
                    <h1 className="main-title">{headerTitle}</h1>
                </div>
                <DocumentConfigBox
                    handleSave={handleSave}
                    documentType={headerTitle}
                    documentName={docName}
                />
                <PremiumLoginBox
                    isPremium={user?.isPremium}
                    email={user?.email}
                    onPremiumClick={() => console.log('Get Premium clicked')}
                    onLogoutClick={() => console.log('Sign Out clicked')}
                />
            </div>
        </header>
    )
};
export default Header;