// src/components/OneStopPage.js
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory from React Router
import './OneStopPage.css'; // Import your stylesheet for styling
import WaveBackground from './common/WaveBackground'
import Footer from './common/Footer';

const OneStopPage = () => {
  const nav = useNavigate(); // Initialize useHistory
  const featureSectionRef = useRef(null);
  const capabilitiesSectionRef = useRef(null);
  const logoBackgroundImageUrl = process.env.PUBLIC_URL + "/images/OneStopLogo.jpg"

  const [waveBackgroundImageUrl, setWaveBackgroundImageUrl] = useState(process.env.PUBLIC_URL + "/images/desktop/stacked-waves-haikei-grey-blue.svg");
  const [blobBackgroundImageUrl, setBlobBackgroundImageUrl] = useState(process.env.PUBLIC_URL + "/images/desktop/stacked-waves-haikei-blue-grey.svg");

  document.body.style.overflowY = "auto";

  useEffect(() => {

    let threshold = 0.2
    const isMobile = navigator.userAgentData.mobile;
    if (isMobile) {
      threshold = 0.4
      setWaveBackgroundImageUrl(process.env.PUBLIC_URL + "/images/layered-waves-haikei-blue-grey-mobile.svg")
      setBlobBackgroundImageUrl(process.env.PUBLIC_URL + "/images/mobile/stacked-waves-haikei-blue-grey.svg")
    }
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: threshold, // Adjust as needed, 0.5 means 50% of the element is in the viewport
    };

    const callback = async (entries) => {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          // Add a class to trigger animations when the element is in the viewport
          // entry.target.classList.add('in-viewport');
          entry.target.children.forEach(async (child) => {
            if (child.id != "wave-divider" && child.id != "wave-background") {
              child.classList.add('fly-in')
              child.classList.add('in-viewport')
            }
          })
        }
        // else {
        //   // Remove the class when the element is not in the viewport
        //   // entry.target.classList.remove('in-viewport');
        //   entry.target.children.forEach((child) => {
        //     console.log(child)
        //     child.classList.remove('fly-in')
        //   })
        //   // entry.target.classList.remove('fly-in');

        // }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (featureSectionRef.current) {
      observer.observe(featureSectionRef.current);
    }
    if (capabilitiesSectionRef.current) {
      observer.observe(capabilitiesSectionRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div className="one-stop-page">
      {/* Top Section with Tool Name and Catchphrase */}
      <div className="top-section">
        <div className='top-section-image-container'>
          <div className='top-section-image' style={{ backgroundImage: `url(${logoBackgroundImageUrl})` }}>
          </div>
          <div id="wave-divider" className="custom-shape-divider-bottom-grey">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
          </div>
        </div>
        <div className='center-container'>
          <div className='slide-up'>
            <h1>One Stop BETA</h1> <br></br>
            <h2><b>Your Ultimate AI Writing Assistant</b></h2>
            <p><b>Unlock your potential with the one-stop solution for papers, questions, and summaries.</b></p>
            <p >Made for students, by students.</p>
            {/* Get Started Button */}
            <button className="get-started-button" onClick={() => nav('/one-stop-editor-home')}>
              Get Started
            </button>
          </div>
        </div>
      </div>
      {/* Sections to Showcase Tool Capabilities */}
      <section className="capability-section" ref={capabilitiesSectionRef}>
        <WaveBackground backgroundUrl={waveBackgroundImageUrl} />

        {/* <div className='spacer' style={{width: '100%', height: "20px", padding: '20px'}}></div> */}
        <div className="capability" onClick={() => nav('/one-stop-paper')}>
          <h2>Paper Creation</h2>
          <p>Create compelling Papers with ease using our intuitive editor.</p>
          <img src={process.env.PUBLIC_URL + "/images/PaperGeneration.png"} alt="Paper Creation" loading='lazy' />
        </div>

        <div className="capability" onClick={() => nav('/one-stop-summary')}>
          <h2>Summary Building</h2>
          <p>Craft concise summaries effortlessly with our powerful summary editor.</p>
          <img src={process.env.PUBLIC_URL + "/images/SummaryGeneration.png"} alt="Summary Building" loading='lazy' />
        </div>

        <div className="capability" onClick={() => nav('/one-stop-question')}>
          <h2>Question Generation</h2>
          <p>Formulate thought-provoking questions seamlessly using our question editor.</p>
          <img src={process.env.PUBLIC_URL + "/images/QuestionGeneration.png"} alt="Question Generation" loading='lazy' />
        </div>
      </section>


      {/* Additional Features Section */}
      <section className="feature-section" ref={featureSectionRef}>
        <WaveBackground backgroundUrl={blobBackgroundImageUrl} />
        <div id="wave-divider" className="custom-shape-divider-bottom-blue">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
          </svg>
        </div>
        <div className="feature">
          <h2>Writes like you</h2>
          <p>Our tool can use text created by you to copy your patterns of writing, language and emotions, providing a personalized experience.</p>
          <img src={process.env.PUBLIC_URL + "/images/Samples.png"} alt="Copy Writing Patterns" loading='lazy' />
        </div>

        {/* <div className="feature">
          <h2>AI Detection Algorithms</h2>
          <p>Beat AI detection algorithms with our advanced writing techniques, ensuring your content remains original.</p>
          <img src="/images/ai-detection.jpg" alt="AI Detection" loading='lazy' />
        </div> */}

        <div className="feature">
          <h2>Non-Plagiarized Text</h2>
          <p>Generate text that is not plagiarized and cited, giving you the confidence of the originality of your conent.</p>
          <img src={process.env.PUBLIC_URL + "/images/PlagerismChecker.png"} alt="Non-Plagiarized" loading='lazy' />
        </div>
      </section>
      <Footer />
    </div >
  );
};

export default OneStopPage;
