import { React, useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import html2pdf from 'html2pdf.js'

import './PaperGenerator.css'; // Create a CSS file for styling
import './common/ServicesStyles.css';

import InputUploadSection from './common/InputUploadSection';
import LoadingScreen from './common/LoadingScreen';
import Header from './common/Header';
import QuillEditor from './common/QuillEditor';
import Popup from './common/Popup';
import { getDocumentData, saveDocument, firebaseConfig } from './common/DataAccess';
import { BackButton, DownloadButton } from './common/Buttons';
// import BurgerButton from './common/BurgerButton'

import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import Footer from './common/Footer';


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "127.0.0.1", 5001); //DEV

const PaperGenerator = () => {
    const location = useLocation();
    const { docName, importId } = location.state || {};
    const documentType = "paper"

    const user = {
        isPremium: JSON.parse(localStorage.getItem("userStatus")).isPremium,
        email: JSON.parse(localStorage.getItem("userStatus")).email,
    }

    const editorRef = useRef();
    const [numberOfArguments, setNumberOfArguments] = useState(3); // Initial value, adjust as needed
    const [wordCount, setWordCount] = useState(100);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
    const [format, setFormat] = useState("MLA")
    const [paperData, setPaperData] = useState("");
    const [samples, setSamples] = useState([])
    const [id, setId] = useState(importId);
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false);


    const [loading, setLoading] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [dbData, setdbData] = useState(null);

    window.onbeforeunload = function () {
        return "Data will be lost if you leave the page, make sure you save everything you need to save?";
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const remoteData = await getDocumentData(user.email, docName, "paper");
                if (remoteData.success) {
                    editorRef.current.setEditorHtml(remoteData.data.contents.paperData);
                    setdbData(remoteData);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);

            }
        };

        fetchData();
    }, []);

    const handleCheckboxChange = (option) => {
        const updatedCheckboxes = checkedCheckboxes.includes(option)
            ? checkedCheckboxes.filter((item) => item !== option)
            : [...checkedCheckboxes, option];

        setCheckedCheckboxes(updatedCheckboxes);
    };
    const handleEditorChange = (html) => {
        setPaperData(html);
    };

    const handleSetWordCount = (count) => {
        if (count < 1) {
            setWordCount(1);
        }
        else if (count > 1000) {
            setWordCount(1000)
        }
        else {
            setWordCount(count)
        }
    }
    const handleSave = async (isOverride) => {
        setPaperData(editorRef.current.editorHtml)
        var saveButton = document.getElementById("saveButton");
        var paperSource = document.getElementById("inputTextArea");
        var documentName = document.getElementById("documentNameInput");
        var content = { format: format, numberOfArguments: numberOfArguments, wordCount: wordCount, checkedCheckboxes: checkedCheckboxes, paperSource: paperSource.value, paperData: paperData, samples: samples }
        var context = { id: id, userEmail: user.email, name: documentName.value, documentType: documentType, content: content }
        setLoading(true);
        saveButton.disabled = true;

        await saveDocument(isOverride, documentName, context, setPopupMessage, setPopupVisible)
            .then((result) => {
                if (result.notPremium) {
                    alert("You do not have a premium account and cannot use this feature");
                }
                else if (result.success) {
                    setId(result.id);
                }
            })
        setLoading(false);
        saveButton.disabled = false;
    };

    const handleOK = async () => {
        await handleSave(true);
    }
    const handleCancel = async () => {
        console.log("Nothing")
    }
    const handleGenerate = async () => {
        var generateButton = document.getElementById("generateButton");
        var paperSource = document.getElementById("inputTextArea");
        if (paperSource.value.trim() == "") {
            alert("Please enter a valid paper source")
        }
        else {
            var paperObj = { userEmail: user.email, samples: samples, paperSource: paperSource.value, paperContents: checkedCheckboxes, numberOfArguments: numberOfArguments, wordCount: wordCount, formatting: format }
            const createPaper = httpsCallable(functions, 'createPaper');
            setLoading(true);
            generateButton.disabled = true;
            await createPaper({ paperObj: paperObj })
                .then((result) => {
                    generateButton.disabled = false;
                    const data = result.data;
                    if (result.data.notPremium) {
                        alert("You do not have a premium account and cannot use this feature");
                    }
                    else {

                        if (data != null) {
                            for (var key in data) {
                                if (data.hasOwnProperty(key)) {
                                    let contentObject = {};
                                    contentObject[key] = data[key];
                                    editorRef.current.addContentFromObject(contentObject);
                                }
                            }
                        }
                    }
                })
                .catch((ex) => {
                    console.log(ex);
                    setLoading(false);
                    generateButton.disabled = false;
                    alert("There was a problem generating your questions.")
                })
            setLoading(false);
        }
    }
    const handleDownload = async () => {
        setLoading(true);
        var content;
        var docName;
        var paperSource = document.getElementById("inputTextArea").value;
        if (paperData != null && paperData.trim() != "") {
            content = paperData;
            docName = paperSource.slice(0, 21) + "-paper.pdf"
        }
        else {
            alert("Generate some text first!")
        }
        // Configuration for html2pdf
        const pdfOptions = {
            margin: 10,
            filename: docName,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css'] }
        };
        await html2pdf().set(pdfOptions).from(content).save()
            .catch((ex) => {
                console.log(ex)
            });
        setLoading(false);
    }

    const loadFromStorage = async () => {
        setLoading(true);
        var documentData = dbData.data.contents;
        if (dbData.success) {
            setdbData(null);
            setFormat(documentData.format);
            setWordCount(documentData.wordCount);
            setCheckedCheckboxes(documentData.checkedCheckboxes);
            setNumberOfArguments(documentData.numberOfArguments);
            setSamples(documentData.samples)
            if (documentData.paperData != null) {
                setPaperData(documentData.paperData);
            }
            var paperSource = document.getElementById("inputTextArea");
            paperSource.value = documentData.paperSource;
        }
        else {
            console.log("Unable to get document data")
        }
        setLoading(false);

    }
    if (user != null && docName != null && dbData != null) {
        loadFromStorage()
    }

    return (
        <div className='paper-generator'>
            <LoadingScreen loading={loading} />
            <Popup message={popupMessage} isVisible={popupVisible} onOK={handleOK} onCancel={handleCancel} setPopupVisible={setPopupVisible} />
            <BackButton />
            <Header handleSave={handleSave} headerTitle={"Paper Generator"} docName={docName} />

            <div className='vertical-layout fly-in'>
                <InputUploadSection inputType={"Paper"} handleGenerate={handleGenerate} samples={samples} setSamples={setSamples} />
                <div className='output-container'>
                    <div className='title'>
                        <h3>Generated Paper</h3>
                    </div>
                    <div className="generation-customization">
                        <div className='checkbox-container'>
                            <label className='customization-label'>Paper Contents:</label>
                            <div className="checkbox-area">
                                {/* Add checkboxes for paper contents options */}
                                <label>
                                    <input type="checkbox"
                                        value="Intro"
                                        checked={checkedCheckboxes.includes('Intro')}
                                        onChange={() => handleCheckboxChange('Intro')} /> Intro
                                </label>
                                <label>
                                    <input type="checkbox"
                                        value="Body"
                                        checked={checkedCheckboxes.includes('Body')}
                                        onChange={() => handleCheckboxChange('Body')} /> Body Paragraphs
                                </label>
                                <label>
                                    <input type="checkbox"
                                        value="Conclusion"
                                        checked={checkedCheckboxes.includes('Conclusion')}
                                        onChange={() => handleCheckboxChange('Conclusion')} /> Conclusion
                                </label>
                                {/* Add more checkboxes as needed */}
                            </div>
                        </div>

                        <div className="spinner-2">
                            <label className='customization-label'>Word Count:</label>
                            <input id="wordCount" className='generation-input'
                                type="number"
                                min="100"
                                max="1000"
                                value={wordCount}
                                onChange={(e) => handleSetWordCount(e.target.value)}
                            />
                        </div>

                    </div>

                    <div className="generation-customization">
                        <div className="options-dropdown">
                            {/* Add combo box for formatting options */}
                            <label className='customization-label'>Format: </label>
                            <select id="format" value={format} onChange={(e) => setFormat(String(e.target.value))}>
                                <option value="mla">MLA</option>
                                <option value="apa">APA</option>
                            </select>
                        </div>
                        <div className="options-slider" >
                            <label className='customization-label'>Number of Arguments:</label>
                            <input id="numberOptions" className='generation-input'
                                type="range"
                                min="1"
                                max="5"
                                value={numberOfArguments}
                                onChange={(e) => setNumberOfArguments(Number(e.target.value))}
                            />
                            <span>{numberOfArguments}</span>
                        </div>
                    </div>

                    <hr className='tab-hr'></hr>
                    <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet"></link>
                    <QuillEditor ref={editorRef} onEditorChange={handleEditorChange} />
                    <div className='downloadContainer'>
                        <DownloadButton generateFile={handleDownload} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default PaperGenerator;