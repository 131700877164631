// OneStopEditorHome.js
import React, { useState, useRef, useEffect } from 'react';
import PremiumLoginBox from './common/PremiumLoginBox';
import { useNavigate } from 'react-router-dom'; // Import useHistory from React Router
import './OneStopEditorHome.css';
import { getDocuments, getSamples, saveSample, getSampleData, getDocId } from './common/DataAccess';
import LoadingScreen from './common/LoadingScreen';
import WaveBackground from './common/WaveBackground';
import { AddSampleButton } from './common/Buttons'
import SampleCustomization from './common/SampleCustomization'
import DocumentCusotmization from './common/DocumentCustomization'
import Popup from './common/Popup';
import Footer from './common/Footer';
import ToolTip from './common/ToolTip';


const OneStopEditorHome = () => {
  const nav = useNavigate(); // Initialize useHistory
  var user = {
    isPremium: JSON.parse(localStorage.getItem("userStatus")).isPremium,
    email: JSON.parse(localStorage.getItem("userStatus")).email
  }
  const [searchText, setSearchText] = useState('');
  const [sortByDate, setSortByDate] = useState('descending'); // 'descending' or 'ascending'
  const [sortByType, setSortByType] = useState('a-z');
  const [updateData, setUpdateData] = useState(false);

  const [isEditingSample, setIsEditingSample] = useState(false);
  const [sampleName, setSampleName] = useState("");
  const [sampleContent, setSampleContent] = useState("")

  const [recentDocuments, setDocuments] = useState([]);
  const [yourSamples, setSamples] = useState([]);

  const [sampleId, setSampleId] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isDocumentsPopupVisible, setDocumentsPopupVisible] = useState(false);
  const [isSampleMessagePopupVisible, setIsSampleMessagePopupVisible] = useState(false);
  const [sampleMessagePopup, setSampleMessagePopup] = useState("");

  const [loading, setLoading] = useState(true);
  // const [docsData, setDocsData] = useState(null);
  // const [sampleData, setSampleData] = useState(null);

  const waveBackgroundImageUrl = process.env.PUBLIC_URL + "/images/stacked-waves-haikei-grey.svg"
  document.body.style.overflowY = "scroll";


  useEffect(() => {
    setLoading(true)
    var docsData = null;
    var sampleData = null;

    const fetchAndLoadData = async () => {
      user = {
        isPremium: JSON.parse(localStorage.getItem("userStatus")).isPremium,
        email: JSON.parse(localStorage.getItem("userStatus")).email
      }
      const remoteDocsData = await getDocuments(user.email, ["question", "paper", "summary"]);
      if (remoteDocsData.success) {
        docsData = remoteDocsData.data;
      }
      else if (!remoteDocsData.success) {
        if (remoteDocsData.userNotFound) {
          setLoading(false);
          alert("Not signed in")
        }
        else if (remoteDocsData.documentsNotFound) {
          console.log("Documents not found")
        }
      }
      else {
        console.error('Error fetching data');
        alert("Error loading recents data")
      }
      const remoteSamplesData = await getSamples(user.email, ["question", "paper", "summary"]);
      if (remoteSamplesData.success) {
        sampleData = remoteSamplesData.data;
      }
      else if (!remoteSamplesData.success) {
        if (remoteSamplesData.userNotFound) {
          setLoading(false);
          alert("Not signed in")
        }
        else if (remoteSamplesData.documentsNotFound) {
          console.log("Documents not found")
        }
      }
      else {
        console.error('Error fetching data');
        alert("Error loading sample data")
      }

      const loadData = () => {
        setLoading(true);
        let docs = []
        let samples = []

        var data = docsData;
        if (data != false) {
          data.forEach((element, index) => {
            var formattedDate = "";
            if (element.value.timestamp != null) {
              let timestampSeconds = element.value.timestamp._seconds;
              const date = new Date(timestampSeconds * 1000); // Convert seconds to milliseconds
              formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
              docs.push({ id: index, type: element.value.type.charAt(0).toUpperCase() + element.value.type.slice(1), name: element.name, date: formattedDate });
            }
          })
        }
        data = sampleData;
        if (data != false && data != null) {
          data.forEach((element, index) => {
            var formattedDate = "";
            if (element.value.timestamp != null) {
              let timestampSeconds = element.value.timestamp._seconds;
              const date = new Date(timestampSeconds * 1000); // Convert seconds to milliseconds
              formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
              samples.push({ id: index, name: element.name, date: formattedDate });
            }
          })

        }
        setDocuments(docs)
        setSamples(samples)
      }
      if (docsData != null && sampleData != null && user != null) {
        loadData();
      }
      setLoading(false);
    };

    fetchAndLoadData();
  }, [updateData]);


  const services = [
    { title: 'Question', description: 'Generate thought-provoking questions effortlessly.' },
    { title: 'Paper', description: 'Create compelling Papers with ease using our intuitive editor.' },
    { title: 'Summary', description: 'Craft concise summaries effortlessly with our powerful summary editor.' },
    // Add more services as needed
  ];


  const servicesListRef = useRef(null);

  const handleBack = () => {
    nav('/')
  }

  const openPopup = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setDocumentsPopupVisible(false);
    setUpdateData(!updateData);
    // document.body.style.overflowY = "auto";
  };

  const handleScroll = (direction) => {
    if (direction === 'left') {
      document.getElementById("scroll-list").scrollLeft -= 200;
    } else if (direction === 'right') {
      document.getElementById("scroll-list").scrollLeft += 200;
    }
  };

  const handleServiceClicked = async (service) => {
    nav('/one-stop-' + service.title.toLowerCase(), { state: { user } });
  }

  const handleDocumentClicked = async (docName, documentType) => {
    setLoading(true);
    let importId = await getDocId(user.email, docName, "documents");
    setLoading(false);
    nav('/one-stop-' + documentType.toLowerCase(), { state: { docName, importId } })
  }

  const handleSampleClicked = async (targetSample) => {
    setLoading(true);
    setIsEditingSample(true)
    // document.body.style.overflowY = "hidden";

    setSampleName(targetSample);
    setSampleId(await getDocId(user.email, targetSample, "samples"));
    var response = await getSampleData(user.email, targetSample);
    if (response.success) {
      setSampleContent(response.data.contents.sampleContent)
    }
    else {
      alert("There was a problem loading some data");
    }
    setLoading(false);
  }
  const onClose = () => {
    setIsEditingSample(!isEditingSample);
    setUpdateData(!updateData);
    // document.body.style.overflowY = "auto";

  }

  const handleOK = async () => {
    await handleSave(true)
  }
  const handleCancel = () => {
    console.log("Nothing")
  }
  const handleSave = async (isOverride) => {
    var content = { sampleContent: sampleContent }
    var context = { id: sampleId, userEmail: user.email, name: sampleName, content: content }
    setLoading(true);
    var response = await saveSample(isOverride, sampleName, context, setSampleMessagePopup, setIsSampleMessagePopupVisible)
    if (!response) {
      // alert("There was a problem saving some data")
    }
    else {
      setIsEditingSample(!isEditingSample);
    }
    setLoading(false);
    setUpdateData(!updateData);
  }



  const filteredDocuments = recentDocuments.filter(document =>
    document.name.toLowerCase().includes(searchText.toLowerCase())
  );
  const filteredSamples = yourSamples.filter(sample =>
    sample.name.toLowerCase().includes(searchText.toLowerCase())
  );

  // const sortedDocuments = [...filteredDocuments].sort((a, b) => {
  //   const dateA = new Date(a.date);
  //   const dateB = new Date(b.date);

  //   if (sortByDate === 'descending') {
  //     return dateB - dateA;
  //   } else {
  //     return dateA - dateB;
  //   }
  // });

  // const sortedSamples = [...filteredSamples].sort((a, b) => { // TODO: fix alphabet sort
  //   if (sortByType == "a-z") {
  //     return 1;
  //   }
  //   else {
  //     return -1;
  //   }
  // })

  // const toggleSortByDate = () => {
  //   setSortByDate(sortByDate === 'descending' ? 'ascending' : 'descending');
  // };

  // const toggleSortByType = () => {
  //   setSortByType(sortByType === "a-z" ? "z-a" : "a-z")
  // }

  return (
    <div className="one-stop-editor-home">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

      {/* Header Section */}
      <header className="header">
        <div className="header-container">
          <div className="title slide-up">
            <h1 className="main-title">One Stop Editor</h1>
          </div>
          <div className="back-button slide-up">
            {/* Back Button */}
            <button onClick={handleBack}><i className="material-icons">arrow_back</i></button>
          </div>
          <PremiumLoginBox
            onPremiumClick={() => console.log('Get Premium clicked')}
          />
        </div>

      </header>

      <WaveBackground backgroundUrl={waveBackgroundImageUrl} />
      <LoadingScreen loading={loading} />
      <Popup message={sampleMessagePopup} isVisible={isSampleMessagePopupVisible} onOK={handleOK} onCancel={handleCancel} setPopupVisible={setIsSampleMessagePopupVisible} />

      {isEditingSample && (
        <div className={`sample-customization-overlay ${isEditingSample ? 'active' : ''}`}>
          <div className={`sample-customization ${isEditingSample ? 'active' : ''}`}>
            <span className="close" onClick={onClose}>&times;</span>
            <h2>Sample Customization</h2>

            <div className="new-sample-section">
              <h3>Add New Sample</h3>
              <div>
                <label>Sample Name:</label>
                <input type="text" value={sampleName} onChange={(e) => setSampleName(e.target.value)} />
              </div>
              <div>
                <label>Sample Content:</label>
                <textarea value={sampleContent} onChange={(e) => setSampleContent(e.target.value)}></textarea>
              </div>
              <div className="separator">Or</div>
              <div>
                <label>Upload Document:</label>
                {/* Add your file upload component here */}
              </div>
              <div className='button-container'>
                <button id="saveButton" onClick={handleSave}>Save</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='container fly-in'>
        {/* Services Scroller Section */}
        <section className="services-section">
          <div className="center-container">
            {/* Left Navigation Icon */}
            <div className="scroll-icon left" onClick={() => handleScroll('left')}>
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </div>

            {/* Services Container */}
            <div className="services-list" id="scroll-list" ref={servicesListRef}>
              {services.map((service, index) => (
                <button key={index} className="service-box" onClick={() => handleServiceClicked(service)}>
                  <h2>{service.title}</h2>
                  <p>{service.description}</p>
                </button>
              ))}
            </div>

            {/* Right Navigation Icon */}
            <div className="scroll-icon right" onClick={() => handleScroll('right')}>
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </div>
        </section>

        {/* Search and Filters Section */}
        <section className="search-filters-section">
          <div className="center-container">
            <div className="search-box">
              <i className="fa fa-search" aria-hidden="true"></i>
              <input
                type="text"
                placeholder="Filter"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)} />
            </div>
            {/* <div className="filters-box">  <button className="filter-option" onClick={toggleSortByDate}>Date {sortByDate === 'descending' ? '↓' : '↑'}</button>
              <button className="filter-option" onClick={toggleSortByType}>Type: {sortByType === "a-z" ? 'a-z' : 'z-a'}</button> </div>*/}
          </div>
        </section>

        {/* Grid Section */}
        <section className="your-section">
          <div className="center-container">
            <div className='your-list'>
              {/* Recents List */}
              <div className="list-view half-width">
                <div className='title-button-pair'>
                  <div className='title-tooltip'>
                    <h2>Your Documents</h2>
                    <ToolTip toolTipText={"Your documents will appear here, click to edit or 🖉 to delete"} />
                  </div>
                  <AddSampleButton setSamplePopUpVisible={setDocumentsPopupVisible} />
                </div>
                <hr></hr>
                {filteredDocuments.map((document) => (
                  <button key={document.id} className="document-item" onClick={() => handleDocumentClicked(document.name, document.type)}>
                    <div className="document-info" >
                      <h3>{document.type} - {document.name}</h3>
                      <p>{document.date}</p>
                    </div>
                  </button>
                ))}
              </div>

              {/* Your Samples List */}
              <div className="list-view half-width">
                <div className='title-button-pair'>
                  <div className='title-tooltip'>
                    <h2>Your Samples</h2>
                    <ToolTip toolTipText={"Your samples will appear here, click to edit or 🖉 to create or delete"} />
                  </div>
                  <AddSampleButton setSamplePopUpVisible={setPopupVisible} />
                </div>
                <hr></hr>
                {filteredSamples.map((sample) => (
                  <button key={sample.id} className="document-item" onClick={() => handleSampleClicked(sample.name)}>
                    <div className="document-info">
                      <h3>{sample.name}</h3>
                      <p>{sample.date}</p>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      {isPopupVisible && <SampleCustomization onClose={closePopup} />}
      {isDocumentsPopupVisible && <DocumentCusotmization onClose={closePopup} />}

      <Footer />
    </div>
  );
};

export default OneStopEditorHome;
