import React, { useEffect } from "react"
import "./ServicesStyles.css"
import { useNavigate } from 'react-router-dom';


const BurgerButton = ({ toggleDrawer }) => {

    const handleClick = () => {
        toggleDrawer()
    };

    useEffect(() => {
        var icon = document.getElementById("burger-icon")
        icon.src = process.env.PUBLIC_URL + `/images/burger-menu-svgrepo-com.svg`
    }, []);
    return (
        <div className='burger-button'>
            <button onClick={handleClick}>
                <img id="burger-icon"></img>
            </button>
        </div>
    )
}

export const BackButton = () => {
    var nav = useNavigate();

    const handleBack = () => {
        nav('/one-stop-editor-home')
    };
    return (
        <div className='back-button slide-up'>
            <button onClick={handleBack}><i className="material-icons">arrow_back</i></button>
        </div>
    )
}


export const AddSampleButton = ({ setSamplePopUpVisible }) => {

    const handleClick = () => {
        if (setSamplePopUpVisible != null) {
            document.body.style.overflowY = "hidden";
            setSamplePopUpVisible(true);
        }
    }
    return (
        <div>
            {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" /> */}
            <button className="add-sample-button" onClick={handleClick}>
                <span className="glyphicon glyphicon-pencil"></span>
            </button>

        </div>

    )
}

export const DownloadButton = ({ generateFile }) => {
    const handleDownload = () => {
        generateFile();
    };

    return (
        <div className='download-button-container'>
            <button className="download-button" onClick={handleDownload} title="Download File">
                &#x2B07; {/* Unicode character for download glyph */}
            </button>
        </div>
    );
};

export default BackButton
