// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useAuth } from './components/AuthContext';
import OneStopPage from './components/OneStopPage';
import OneStopSignUpLogin from './components/OneStopSignUpLogin'; // Create AnotherPage component
import OneStopEditorHome from './components/OneStopEditorHome'
import QuestionGenerator from './components/QuestionGenerator';
import PaperGenerator from './components/PaperGenerator'
import SummaryGenerator from './components/SummaryGenerator';
import Footer from './components/common/Footer';

const App = () => {
  const { isAuthenticated } = useAuth();
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<OneStopPage />} />
        <Route path="/one-stop-signup-login" element={<OneStopSignUpLogin />} />
        <Route path="/one-stop-editor-home"
          element={isAuthenticated ? <OneStopEditorHome /> : <Navigate to="/one-stop-signup-login" />} />
        <Route path="/one-stop-question"
          element={isAuthenticated ? <QuestionGenerator /> : <Navigate to="/one-stop-signup-login" />} />
        <Route path="/one-stop-paper"
          element={isAuthenticated ? <PaperGenerator /> : <Navigate to="/one-stop-signup-login" />} />
        <Route path="/one-stop-summary"
          element={isAuthenticated ? <SummaryGenerator /> : <Navigate to="/one-stop-signup-login" />} />

      </Routes>
    </Router>
  );
};

export default App; 