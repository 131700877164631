// PremiumLoginBox.js
import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import UserInfoModal from './UserInfoModal';
import './PremiumLoginBox.css'; // Create a corresponding CSS file for styling

const PremiumLoginBox = ({ onPremiumClick }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { logout } = useAuth();

  const isPremium = JSON.parse(localStorage.getItem("userStatus")).isPremium;
  const email =  JSON.parse(localStorage.getItem("userStatus")).email;

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="premium-login-box ">
      <div className="user-info-box slide-up" onClick={openModal}>
        <div className="user-info">
          {isPremium && <span className="premium-indicator">P</span>}
          <div className="user-account">
            <span className="user-email">{email.slice(0, 8)}</span>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <UserInfoModal
          email={email}
          isPremium={isPremium}
          onPremiumClick={onPremiumClick}
          onSignOutClick={logout}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default PremiumLoginBox;
