// src/components/OneStopSignUpLogin.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './OneStopSignUpLogin.css';
import LoadingScreen from './common/LoadingScreen';
import WaveBackground from './common/WaveBackground';

const OneStopSignUpLogin = () => {
    const [isSignUp, setIsSignUp] = useState(true);
    const [isHidden, setIsHidden] = useState(false);
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();
    const nav = useNavigate();

    const [blobBackgroundImageUrl, setBlobBackgroundImageUrl] = useState(process.env.PUBLIC_URL + "/images/desktop/stacked-waves-haikei-grey-blue.svg");


    // console.log(localStorage.getItem("userStatus"))
    if (localStorage.getItem("userStatus") != null && JSON.parse(localStorage.getItem("userStatus")).isAuthenticated) {
        // console.log(JSON.parse(localStorage.getItem("userStatus")));
        nav('/one-stop-editor-home')
    }

    const toggleView = () => {
        setIsHidden(true);
        setTimeout(() => {
            setIsSignUp(!isSignUp);
            setIsHidden(false);
        }, 500);
    };

    const handleBack = () => {
        nav('/')
    }
    const handleProviderLogin = async (provider) => {
        try {
            setLoading(true);
            // Open a pop-up for Google authentication
            var result = await login(provider);
            if (result == true) {
                // Redirect to OneStopEditorHome upon successful authentication
                setLoading(false);
                nav('/one-stop-editor-home');
            }
            else {
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <div className="one-stop-sign-up-login">
            <LoadingScreen loading={loading} />
            <WaveBackground backgroundUrl={blobBackgroundImageUrl}/>
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

            <div className="back-button">
                {/* Back Button */}
                <button onClick={handleBack}><i className="material-icons">arrow_back</i></button>
            </div>


            <div className={`provider-container ${isHidden ? 'hidden' : ''}`}>
                <h2>{isSignUp ? 'Sign Up' : 'Login'} with External Providers</h2>
                <div className="provider-buttons">
                    <button onClick={() => handleProviderLogin('Google')}>
                        <i className="fa fa-google" aria-hidden="true"></i>
                        Google
                    </button>
                    {/* <button onClick={() => handleProviderLogin('Outlook')}>
                            <img src="outlook-icon.png" alt="Outlook" />
                            Outlook
                        </button>
                        <button onClick={() => handleProviderLogin('GitHub')}>
                            <img src="github-icon.png" alt="GitHub" />
                            GitHub
                        </button>
                        <button onClick={() => handleProviderLogin('Azure')}>
                            <img src="azure-icon.png" alt="Azure" />
                            Azure
                        </button>
                        <button onClick={() => handleProviderLogin('Microsoft')}>
                            <img src="microsoft-icon.png" alt="Microsoft" />
                            Microsoft
                        </button> */}
                </div>
            </div>


            {/* Container for "Already have an account" button */}
            <div className={`already-have-account ${isHidden ? 'hidden' : ''}`}>
                <p onClick={toggleView}>
                    {isSignUp ? 'Already have an account? Login here.' : 'Don\'t have an account? Sign up here.'}
                </p>
            </div>
        </div>
    );
};

export default OneStopSignUpLogin;
