import React from 'react';
import { css } from '@emotion/react';
import { PacmanLoader } from 'react-spinners';
import Modal from 'react-modal';


//https://jonjaques.github.io/react-loaders/

// Override modal styles to center the content
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  overlay: {zIndex: 9999}
};


const LoadingScreen = ({ loading }) => {
  return (
    <Modal
      isOpen={loading}
      style={modalStyles}
      ariaHideApp={false} // Prevents a11y error
    >
      <div className="loading-container">
        <PacmanLoader color="#3498db" loading={loading} size={100} />
        <h3 style={{ marginTop: '20px', textAlign: 'center' }}>Loading</h3>
      </div>
    </Modal>
  );
};


export default LoadingScreen;
