import { createContext, useContext, useState } from 'react';
import { signInWithProvider, getPremiumStatus, auth } from './common/DataAccess';

import { onAuthStateChanged } from 'firebase/auth'

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  async function setLogin(email, isSuccess) {
    if (isSuccess && email != null) {
      setIsAuthenticated(true);
      localStorage.setItem("userStatus", JSON.stringify({ isAuthenticated: true, email: email, isPremium: await (getPremiumStatus(email)) }));
      // console.log(JSON.parse(localStorage.getItem("userStatus")));
    }
    else {
      setIsAuthenticated(false);
      localStorage.setItem("userStatus", JSON.stringify({ isAuthenticated: false, email: false, isPremium: false }));
      // console.log(JSON.parse(localStorage.getItem("userStatus")));
    }
  }

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setLogin(user.email, true)
    } else {
      setLogin(null, false);
    }
  });

  const login = async (provider) => {
    var response = await signInWithProvider(provider)
    .then(async (response) => {
      if (response.user != null && response.user.emailVerified) {
        await setLogin(response.user.email, true);
        return true;
      }
      else {
        await setLogin(null, false);
        return false
      }
    })
    .catch((ex) => {
      return false;
    })
    return response;
  };

  const logout = () => {
    auth.signOut().then(function () {
      setIsAuthenticated(null, false);
      console.log("signed out");
    })
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
