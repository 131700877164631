import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DocumentConfigBox.css'; // Create a corresponding CSS file for styling
import LoadingScreen from './LoadingScreen';

const DocumentConfigBox = ({ handleSave, documentType, documentName }) => {

    if (documentType.toLowerCase().includes("question")) {
        documentType = "question set";
    }
    else if (documentType.toLowerCase().includes("paper")) {
        documentType = "paper name";
    }
    else if (documentType.toLowerCase().includes("summary")) {
        documentType = "summary name";
    }
    var docName = `Default ${documentType}`;
    if (documentName != null && String(document).trim() != "") {
        docName = documentName;
    }
    const [questionSetName, setQuestionSetName] = useState(docName);


    return (
        <div className='document-config slide-up'>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
            {/* Question Set Name Input */}
            <input id="documentNameInput"
                type="text"
                value={questionSetName}
                onChange={(e) => setQuestionSetName(e.target.value)}
            />

            {/* Save Button */}
            <div className='save-button'>
                <button id="saveButton" onClick={handleSave}><i className="fa fa-save"></i></button>
            </div>
        </div>
    );
};

export default DocumentConfigBox;